.App {
  text-align: center;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#header {
	position: fixed;
	height: 81px;
	width: 100%;
	z-index: 1000;
	background: rgba(47, 72, 102, 1);
	background: -moz-linear-gradient(-45deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
	background: linear-gradient(135deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f4866', endColorstr='#6d7f94', GradientType=1);
	margin: 0px;
}

#banner {text-align: center;}

#banner ul a {
	color: #ffffff !important;
	text-decoration: none;
	border: 1px solid #ffffff;
	font-size: 14px;
	display: block;
	line-height: 14px;
	padding: 4px 6px;
	width: 220px !important;
    text-align: center !important;
}

#banner ul a:hover {opacity: 0.7;}

#logo, #logo_en {
	display: block;
	width: 220px;
	padding: 15px 15px;
	position: absolute;
	left: 0px;
	top: 0px;
	border-bottom: 0px dashed #335784 !important;
}

#logo img, #logo_en img {
	display: block;
	width: 100%;
	height: auto;
}

#banner {
	padding-top: 80px;
	margin-bottom: 100px;
}

#body {
	padding-top: 100px;
	margin-bottom: 100px;
}

#title {
  position:relative;
  top: -55px;
  color: white;
  font-size: x-large;	
  text-align: right;	
}

#link {    
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: large;		
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
	position: relative;
	display: block;
	margin: 0px auto;
	max-width: 940px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
